import WideNewsletter from 'components/Common/WideNewsletter'
import CardView from 'components/Home/CardView'
import HomeParallaxWrapper from 'components/Home/HomeParallaxWrapper'
// TODO: Uncomment to re-use the MostLiked UI to do the MostViewed components
// import MostLiked from 'components/Home/MostLiked'
import Recap from 'components/Home/Recap'
import TopCarousel from 'components/Home/TopCarousel'
import useFetchQueueItemsUuid from 'hooks/hook-fetch-queue-items-uuid'
import {
  setVariables,
  pushVirtualPageview,
  variablesMapping,
  dispatchResetVariablesEvent,
} from 'lib/gtm'
import isEmpty from 'lodash/isEmpty'
import slice from 'lodash/slice'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { color } from 'styles/mixins'

const HomeParallax = styled(HomeParallaxWrapper)`
  background: ${color.sunshineyellow};
`
const numberOfTopCarousel = 5
const numberOfInitCardStack = 15
const numberOfAdvertOnCardStack = 1

const HomeView = ({ routeData = {} }) => {
  const { isClientRoute = false } = routeData

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true)

  const advertOption = {
    zone: 'youngpost_discover/home',
    index: 7,
    count: numberOfAdvertOnCardStack,
  }
  const queueName = 'section_top_503648' // Queue - Section - Top: Discover

  const limitToDeduct = numberOfInitCardStack >= advertOption.index ? numberOfAdvertOnCardStack : 0

  const { fetchNext: fetchNextQueue, context: entityUuids } = useFetchQueueItemsUuid({
    queueName,
    offset: 0,
    limit: numberOfTopCarousel + numberOfInitCardStack - limitToDeduct,
    ssr: true,
  })

  const entityUuidsTopCarousel = slice(entityUuids, 0, numberOfTopCarousel)
  const entityUuidsCardView = slice(entityUuids, numberOfTopCarousel)

  const loadMoreCallback = () => {
    fetchNextQueue({
      nextLimit: 3,
      callback: (haveNext) => {
        if (!haveNext) setShowLoadMoreButton(false)
      },
    })
  }

  const newsletterGaData = {
    event_category: 'Newsletter (Discover - Inline)',
    event_label: 'Discover',
    content_type: 'discover',
    section: 'discover',
  }

  const newsletterGa4CustomizedParameters = {
    ypTriggerPoint: 'homepage_bottom',
  }

  useEffect(() => {
    if (isClientRoute) {
      dispatchResetVariablesEvent()
      setVariables(variablesMapping('home', {}))
      pushVirtualPageview()
    }
  }, [isClientRoute])

  return (
    <>
      <HomeParallax>
        {entityUuidsTopCarousel.length > 1 && <TopCarousel entityUuids={entityUuidsTopCarousel} />}
        {!isEmpty(entityUuidsCardView) && (
          <CardView
            title={'Best Of The Rest'}
            entityUuids={entityUuidsCardView}
            advertOption={advertOption}
            loadMoreCallback={loadMoreCallback}
            showLoadMoreButton={showLoadMoreButton}
          />
        )}
        <WideNewsletter
          newsletterGaData={newsletterGaData}
          newsletterGa4CustomizedParameters={newsletterGa4CustomizedParameters}
        />
        <Recap
          title={'Top Stories Recap'}
          queueName={queueName}
          numberOfEachRow={10}
          numberOfRow={5}
        />
        {/* TODO: Uncomment to re-use the MostLiked UI to do the MostViewed components */}
        {/* {!isEmpty(stateMostLikeEntityUuids) && <MostLiked entityUuids={stateMostLikeEntityUuids} />} */}
      </HomeParallax>
    </>
  )
}

HomeView.propTypes = {
  routeData: PropTypes.object,
}

export default HomeView
