import HomeView from 'components/layout/HomeView'
import get from 'lodash/get'
import { useEffect } from 'react'
import { useStore } from 'store'

const Home = ({ query }) => {
  const {
    headerState: { dispatch: headerDispatch },
  } = useStore()
  const isClientRoute = get(query, 'routerSide') === 'client'

  useEffect(() => {
    headerDispatch({ type: 'setScrollableWrapperMode', payload: { value: true } })
    return () => {
      headerDispatch({ type: 'setScrollableWrapperMode', payload: { value: false } })
    }
  }, [headerDispatch])

  return (
    <>
      <HomeView routeData={{ isClientRoute }} />
    </>
  )
}

export default Home
